<template>
  <div class="internal-refund-listing">
    <v-container fluid class="custom-bthrust-style">
      <v-simple-table fixed-header height="67vh">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="simple-table-th" width="15%" align="left">Date</th>
              <th class="simple-table-th" width="15%" align="left">
                Refunded Amount
              </th>
              <th class="simple-table-th" width="15%" align="left">
                Payment Mode
              </th>
              <th class="simple-table-th" width="50%" align="left">Note</th>
              <th class="simple-table-th" width="5%" align="left"></th>
            </tr>
          </thead>
          <tbody>
            <template v-if="refunds.length > 0">
              <tr v-for="(refund, index) in refunds" :key="index">
                <td class="py-2 px-4 simple-table-td" align="left">
                  {{ formatDate(refund.refund_date) }}
                </td>
                <td class="py-2 px-4 simple-table-td" align="left">
                  {{ formatMoney(refund.refunded_amount) }}
                </td>
                <td class="py-2 px-4 simple-table-td" align="left">
                  <v-chip color="cyan white--text" label class="d-inline">
                    {{ refund.payment_mode }}
                  </v-chip>
                </td>
                <td class="py-2 px-4 simple-table-td" align="left">
                  {{ refund.note }}
                </td>
                <td class="py-2 px-4 simple-table-td" align="left">
                  <v-icon
                    v-if="false"
                    v-on:click="do_action(refund, 'edit')"
                    color="cyan"
                    small
                    >mdi-pencil</v-icon
                  >
                  &nbsp;&nbsp;
                  <v-icon
                    v-on:click="do_action(refund, 'delete')"
                    color="red lighten-1"
                    small
                    >mdi-delete</v-icon
                  >
                </td>
              </tr>
            </template>
            <template v-else>
              <template v-if="pageLoading">
                <tr v-for="row in 5" :key="row">
                  <td>
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton table-rows-text"
                    ></v-skeleton-loader>
                  </td>
                  <td>
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton table-rows-text"
                    ></v-skeleton-loader>
                  </td>
                  <td>
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton table-rows-text"
                    ></v-skeleton-loader>
                  </td>
                  <td>
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton table-rows-text"
                    ></v-skeleton-loader>
                  </td>
                </tr>
              </template>
              <tr v-else-if="!pageLoading">
                <td colspan="4">
                  <p
                    class="m-0 row-not-found text-center font-weight-500 font-size-16"
                  >
                    <img
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image"
                    />
                    Uhh... There are no refund at the moment.
                  </p>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
    <Dialog :common-dialog="refund_dialog" :dialog-width="768">
      <template v-slot:title> Refund ({{ CNBarcode }})</template>
      <template v-slot:body>
        <v-container>
          <v-form
            ref="refundForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="submit_refund()"
          >
            <v-row class="mx-1">
              <v-col md="12">
                <v-layout v-if="false">
                  <v-flex md4>
                    <label
                      class="font-weight-600 font-size-18"
                      for="credited-amount"
                      >Credited Amount</label
                    >
                  </v-flex>
                  <v-flex md8>
                    <v-text-field
                      label="Credited Amount"
                      dense
                      filled
                      solo
                      readonly
                      id="credited-amount"
                      flat
                      type="number"
                      color="cyan"
                      v-model="creditedAmount"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout class="mb-3">
                  <v-flex md4>
                    <label
                      class="font-weight-600 font-size-18 required"
                      for="refunded-amount"
                      >Refunded Amount</label
                    >
                  </v-flex>
                  <v-flex md8>
                    <v-text-field
                      label="Refunded Amount"
                      dense
                      filled
                      solo
                      id="refunded-amount"
                      flat
                      hide-details
                      :disabled="refundLoading"
                      type="number"
                      color="cyan"
                      v-model="create_refund.refunded_amount"
                      :rules="[
                        validateRules.required(
                          create_refund.refunded_amount,
                          'Refunded Amount'
                        ),
                      ]"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout class="mb-3">
                  <v-flex md4>
                    <label
                      class="font-weight-600 font-size-18 required"
                      for="refund-date"
                      >Date</label
                    >
                  </v-flex>
                  <v-flex md8>
                    <DatePicker
                      solo
                      flat
                      id="refund-date"
                      required
                      :disabled="refundLoading"
                      :default-date="create_refund.refund_date"
                      v-model="create_refund.refund_date"
                    ></DatePicker>
                  </v-flex>
                </v-layout>
                <v-layout class="mb-3">
                  <v-flex md4>
                    <label
                      class="font-weight-600 font-size-18 required"
                      for="payment-mode"
                      >Payment Mode</label
                    >
                  </v-flex>
                  <v-flex md8>
                    <v-autocomplete
                      dense
                      color="cyan"
                      filled
                      id="payment-mode"
                      :items="paymentModes"
                      v-model.trim="create_refund.payment_mode"
                      label="Payment Mode"
                      solo
                      flat
                      :disabled="refundLoading"
                      item-color="cyan"
                      item-text="text"
                      item-value="text"
                      hide-details
                      :rules="[
                        validateRules.required(
                          create_refund.payment_mode,
                          'Payment Mode'
                        ),
                      ]"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Payment Mode Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
                <v-layout class="mb-3">
                  <v-flex md4>
                    <label class="font-weight-600 font-size-18" for="note"
                      >Note</label
                    >
                  </v-flex>
                  <v-flex md8>
                    <v-textarea
                      label="Note"
                      dense
                      filled
                      id="note"
                      solo
                      flat
                      :disabled="refundLoading"
                      auto-grow
                      color="cyan"
                      row-height="25"
                      v-model="create_refund.note"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="refundLoading"
          v-on:click="refund_dialog = false"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          Close
        </v-btn>
        <v-btn
          :disabled="!formValid || refundLoading"
          :loading="refundLoading"
          v-on:click="submit_refund()"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
        >
          Save
        </v-btn>
      </template>
    </Dialog>
    <Dialog :common-dialog="delete_dialog" :dialog-width="768">
      <template v-slot:title> Delete Confirmation </template>
      <template v-slot:body>
        <v-container>
          <p class="font-weight-500 font-size-18 m-0">
            Are you sure, you want to delete refund of
            <span class="font-weight-600">{{
              formatMoney(row_delete.refunded_amount)
            }}</span>
            paid using
            <span class="font-weight-600">{{ row_delete.payment_mode }}</span
            >?
          </p>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="delete_loading"
          v-on:click="delete_dialog = false"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          Close
        </v-btn>
        <v-btn
          :disabled="delete_loading"
          :loading="delete_loading"
          v-on:click="delete_row()"
          class="mx-2 custom-bold-button white--text"
          color="red lighten-1"
        >
          Yes ! Delete
        </v-btn>
      </template>
    </Dialog>
  </div>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { GET, POST, PATCH, DELETE } from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { CreditNoteEventBus } from "@/core/lib/credit-note/credit-note.lib";

export default {
  mixins: [CommonMixin, ValidationMixin],
  data() {
    return {
      pageLoading: false,
      delete_loading: false,
      delete_dialog: false,
      refund_dialog: false,
      refunds: [],
      refundId: 0,
      refundLoading: false,
      row_delete: {},
      create_refund: {
        refunded_amount: null,
        refund_date: new Date().toISOString().split("T")[0],
        payment_mode: null,
        note: null,
      },
    };
  },
  props: {
    CNBarcode: {
      type: String,
      default: null,
    },
    paymentModes: {
      type: Array,
      default() {
        return [];
      },
    },
    creditId: {
      type: Number,
      default: 0,
    },
    creditedAmount: {
      type: Number,
      default: 0,
    },
    refundedAmount: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Dialog,
    DatePicker,
  },
  methods: {
    do_action(row, action) {
      if (action == "edit") {
        this.open_dialog(row);
      }
      if (action == "delete") {
        this.delete_confirm(row);
      }
    },
    delete_confirm(row) {
      this.row_delete = row;
      this.delete_dialog = true;
    },
    delete_row() {
      const refundId = this.row_delete.id;
      this.delete_loading = true;
      this.$store
        .dispatch(DELETE, {
          url: `credit-note/${this.creditId}/refund/${refundId}`,
        })
        .then(() => {
          this.row_delete = {};
          this.delete_dialog = false;
          this.get_refunds();
          this.$emit("success", true);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.delete_loading = false;
        });
    },
    open_dialog(row) {
      if (row && row.id) {
        this.refundId = row.id;
        this.create_refund = {
          refunded_amount: row.refunded_amount,
          refund_date: row.refund_date,
          payment_mode: row.payment_mode,
          note: row.note,
        };
      } else {
        this.create_refund.refunded_amount =
          this.creditedAmount - this.refundedAmount;
      }
      this.refund_dialog = true;
    },
    submit_refund() {
      const validateStatus = this.$refs.refundForm.validate();

      const formErrors = this.validateForm(this.$refs.refundForm);

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      let REQUEST_TYPE = POST;
      let REQUEST_URL = `credit-note/${this.creditId}/refund`;

      if (this.refundId && this.refundId > 0) {
        REQUEST_TYPE = PATCH;
        REQUEST_URL = `credit-note/${this.creditId}/refund/` + this.refundId;
      }

      this.refundLoading = true;

      this.$store
        .dispatch(REQUEST_TYPE, {
          url: REQUEST_URL,
          data: this.create_refund,
        })
        .then(() => {
          this.clear_all();
          this.get_refunds();
          this.$emit("success", true);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.refundLoading = false;
        });
    },
    clear_all() {
      this.create_refund = {
        refunded_amount: null,
        refund_date: new Date().toISOString().split("T")[0],
        payment_mode: null,
        note: null,
      };

      this.refund_dialog = false;
      this.refundId = 0;
    },
    get_refunds() {
      this.pageLoading = true;
      this.$store
        .dispatch(GET, { url: `credit-note/${this.creditId}/refund` })
        .then(({ data }) => {
          this.refunds = data;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
  },
  beforeDestroy() {
    CreditNoteEventBus.$off("create:refund");
  },
  beforeMount() {
    CreditNoteEventBus.$on("create:refund", (param) => {
      this.refund_dialog = param;
      this.refundId = 0;
      this.create_refund = {
        refunded_amount: null,
        refund_date: new Date().toISOString().split("T")[0],
        payment_mode: null,
        note: null,
      };
    });
  },
  mounted() {
    this.get_refunds();
  },
};
</script>
